






















































































































































import { ConsumerTmallSettings, IAccount, ProviderShopifySettings } from "@/types/interfaces";
import { Component, Vue } from "vue-property-decorator";
import { ActionMethod } from 'vuex';
import { Action, Getter, State } from 'vuex-class';
const cloneDeep = require('lodash/cloneDeep');

@Component
export default class SettingsPage extends Vue {
  private companies: string[];
  private locations: string[];
  private loading: Boolean = true;
  
  @State(state => state.shopify.account) account: IAccount;
  @State(state => state.tmallSettings.settings) consumerTmallSettings: ConsumerTmallSettings;
  @State(state => state.shopifySettings.settings) providerShopifySettings: ProviderShopifySettings;

  @Getter('context/getConsumerName') consumerName: string;

  @Action('fetchLogisticCompanies', { namespace: 'tmallSettings' }) fetchLogisticCompanies: ActionMethod;
  @Action('updateSettings', { namespace: 'tmallSettings' }) updateTmallSettings: ActionMethod;
  @Action('fetchSettings', { namespace: 'tmallSettings' }) fetchTmallSettings: ActionMethod;
  @Action('fetchLocations', { namespace: 'shopifySettings' }) fetchLocations: ActionMethod;
  @Action('fetchSettings', { namespace: 'shopifySettings' }) fetchShopifySettings: ActionMethod;
  @Action('updateSettings', { namespace: 'shopifySettings' }) updateShopifySettings: ActionMethod;

  async mounted() {
    if(this.account) {
      if(this.account.providerShopify) {
        try { await this.fetchShopifySettings() } catch (err) { this.$notify({ type: 'error', text: err.message }) }
        this.locations = await this.fetchLocations()
      }
      if(this.account.consumerTmall) {
        try { await this.fetchTmallSettings() } catch (err) { this.$notify({ type: 'error', text: err.message }) }
        this.companies = await this.fetchLogisticCompanies()
      }
    }
    this.loading = false
  }

  submit() {

    var p1 = this.updateTmallSettings(this.consumerTmallSettings)
    var p2 = this.updateShopifySettings(this.providerShopifySettings)

    Promise.all([p1, p2]).then((result: any) => {
      this.$notify({ type: 'success', text: 'Settings successfully updated' })
    }).catch(err => {
      this.$notify({ type: 'error', text: err.message })
    });
  }

  get shippingEnabled() {
    if(this.providerShopifySettings && this.providerShopifySettings.synchronization && this.providerShopifySettings.synchronization.orders && this.providerShopifySettings.synchronization.orders.shipping) {
      return this.providerShopifySettings.synchronization.orders.shipping.enabled
    }
  }
}
